import React from "react";
import { Box, Typography, Card, Grid } from "@mui/material";

const BannerDePaginaPrincipal = ({ title, subtitle, textColor }) => {
  return (
    <Grid container>
      <Box
        sx={{
          color: textColor || "#211E26",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: { xs: "10px", sm: "20px", md: "40px", xl: "20px 40px" },
          width: "100%",
        }}
      >
        <Card
          sx={{
            borderRadius: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: textColor || "#211E26",
            padding: { xs: "40px 20px", sm: "30px", md: "40px" },
            marginBottom: { xs: "50px", md: "50px" },
            width: "97%",
            height: {xs: "200px", md: "250px"},
            textAlign: "center",
            background: "transparent",
            position: "relative",
            overflow: "hidden",
            border: "1px solid rgba(255, 255, 255, 0.2)",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
            }}
          >
            {title}
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
              }}
            >
              {subtitle}
            </Typography>
          </Typography>
        </Card>
      </Box>
    </Grid>
  );
};

export default BannerDePaginaPrincipal;
