import React from "react";
import { Box } from "@mui/material"; // Asegúrate de importar Box de MUI
import UneteAComunidadLayout from "../components/botones/comunidades/internasInformativas/comunidades";

const ComunidadDeMarketing = () => {
  const title = "¡Únete a Nuestra Comunidad de Marketing!";
  const description =
    "Forma parte de un grupo exclusivo de WhatsApp donde compartimos información relevante sobre marketing digital, estrategias de publicidad, y técnicas avanzadas para ayudarte a crecer en internet. Aprende de los expertos, resuelve tus dudas y conecta con personas que comparten tus intereses. ¡No te pierdas las oportunidades que tenemos para ti!";
  const whatsappLink = "https://chat.whatsapp.com/K0pUOF6DV4fA4QvZBA2qR7";
  const imageUrl = "/images/comunidadDeMarketing.jpg"; 

  return (
    <Box>
      <UneteAComunidadLayout
        title={title}
        description={description}
        whatsappLink={whatsappLink}
        imageUrl={imageUrl}
      />
    </Box>
  );
};

export default ComunidadDeMarketing;
