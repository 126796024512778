import React from "react";
import BannerDePaginaPrincipal from "../components/banners/bannerDePaginaPrincipal/bannerPaginaPrincipal"; // Importa el nuevo componente
import ServicesGrid from "../portfolio/components/ServicesGrid";
import Footer from "../footer/page";
import { Box, Grid2 } from "@mui/material";

const Portfolio = () => {
  return (
    <Box
      sx={{
        padding: 0,
        margin: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <Grid2
        container
        sx={{ padding: 0, margin: 0, width: '100%' }}
      >

        {/* Envío de props al componente BannerDePaginaPrincipal */}
        <BannerDePaginaPrincipal
          title="CLIENTES QUE TRABAJAN"
          subtitle="O TRABAJARON CON NOSOTROS"
          textColor="#211E26"
        />

        <ServicesGrid />
      </Grid2>
      <Footer />
    </Box>
  );
};

export default Portfolio;
