import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import NavGral from "../navbarHomeGral/navbarGral";
import Footer from "../footer/page";

const Copyright = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Card 
                sx={{
                    maxWidth: { xs: '90%', sm: '80%', md: 600 }, // Ajuste responsivo
                    margin: 'auto',
                    marginTop: { xs: 2, md: 4 }, // Espaciado diferente según la resolución
                    padding: { xs: 2, md: 3 }, // Ajuste de padding para diferentes tamaños de pantalla
                    boxShadow: 3, // Añadir un poco de sombra para mejorar la visibilidad en pantallas pequeñas
                }}
            >
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom align="center">
                        Copyright
                    </Typography>
                    <Typography variant="body1" align="justify"> {/* Alineación justificada para mejor legibilidad */}
                        Este sitio web y su contenido tienen derechos de autor de hitpoly – © hitpoly 2024. Todos los derechos reservados. Se prohíbe cualquier redistribución o reproducción de parte o la totalidad del contenido en cualquier forma que no sea la siguiente:

                        Puede imprimir o descargar extractos en un disco duro local únicamente para su uso personal y no comercial.

                        Puede copiar el contenido a terceros individuales para su uso personal, pero solo si reconoce el sitio web como la fuente del material. No puede, excepto con nuestro permiso expreso por escrito, distribuir o explotar comercialmente el contenido. Tampoco podrá transmitirlo ni almacenarlo en ningún otro sitio web u otra forma de sistema de recuperación electrónica.
                    </Typography>
                </CardContent>
            </Card>
            <Footer />
        </div>
    );
}

export default Copyright;
