import React from "react";
import { Box, Card, Typography } from "@mui/material";
import ceo from "../../../assets/ceo.png";
import logoInfo from "../../../assets/logoinfo.png";

const CeoCard = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: { xs: 5, lg: 12 },
        width: { lg: "98vw" },
        backgroundColor: "#FFFFFF", // Fondo blanco
        padding: { xs: "0 20px", lg: "0 40px" },
      }}
    >
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            color: "#211E26", // Violeta Nebuloso
            fontSize: { xs: "1.125rem", sm: "1.125rem", md: "1.25rem" },
          }}
        >
          NUESTRO CEO
        </Typography>

        <Typography
          variant="h3"
          sx={{
            mb: 3,
            color: "#211E26", // Negro Noche
            fontSize: "2.5rem",
            fontWeight: "500",
          }}
        >
          Conoce al fundador
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
          width: {xs: "100%", md: "80%"},
        }}
      >
        {/* Caja con sombra y animación */}
        <Card
          sx={{
            borderRadius: "15px",
            backgroundColor: "#FFFFFF", // Fondo blanco para la caja
            width: { xs: "90%", sm: "80%", md: "50%", lg: "40%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Sombra suave
            transition: "transform 0.3s ease-in-out", // Transición suave para el hover
            "&:hover": {
              transform: "scale(1.05)", // Efecto de acercamiento al pasar el ratón
            },
          }}
        >
          <img
            src={ceo}
            alt="ceo"
            style={{ width: "100%", height: "auto", borderRadius: "15px" }}
          />
        </Card>

        {/* Caja de texto sin sombra ni color adicional */}
        <Box
          sx={{
            padding: { xs: "20px", sm: "30px", md: "40px" },
            color: "#211E26", // Negro Noche
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: { xs: "100%", sm: "80%", md: "60%" },
            backgroundColor: "#FFFFFF", // Caja blanca sin sombra
            borderRadius: "15px", // Bordes redondeados
          }}
        >
          <Typography
            variant="h3"
            sx={{
              mb: 2,
              fontSize: "2.5rem",
            fontWeight: "500",
              color: "#F21C63", // Fuccia Enano para destacar el nombre
            }}
          >
            Denis Olivares
          </Typography>

          <Typography
            variant="body1"
            sx={{
              mb: 2,
              fontSize: "1rem",
              color: "#211E26", // Negro Noche
            }}
          >
            Denis Olivares no solo fundó Hitpoly, también lidera una revolución
            tecnológica con un propósito claro: cambiar el mundo a través de la
            innovación. Con la convicción de que "todo comienza en la mente
            antes de hacerse realidad", Denis se ha comprometido a luchar hasta
            el final para crear un futuro más equitativo y próspero para todos. <br /> <br />
            Imparable y destructor de barreras, ha convertido su visión en acción, liderando
            con estrategias innovadoras que no solo transforman negocios, sino
            también vidas. Su misión es dejar una huella positiva en este
            planeta, utilizando su creatividad y tecnología para generar un
            impacto duradero en la humanidad. Denis no se detiene. Su ambición
            va más allá de lo común, buscando siempre el próximo reto, el
            próximo gran cambio que hará del mundo un lugar mejor.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CeoCard;
