import React from 'react';
import { Box, Typography, Button, Avatar } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, A11y } from 'swiper/modules';

// Componente Reutilizable Slider
const SliderReutilizable = ({
  titulo = "Título del Slider",
  datos = [],
  verMasTexto = "Ver más",
}) => {
  return (
    <Box sx={{ width: '100%', textAlign: 'center', marginTop: 5 }}>
      {/* Título del Slider */}
      <Typography variant="h4" sx={{ marginBottom: 3 }}>
        {titulo}
      </Typography>

      {/* Slider */}
      <Swiper
        modules={[Navigation, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        centeredSlides={true}
        navigation
      >
        {datos.map((item, index) => (
          <SwiperSlide key={index}>
            <Box sx={{ gap: 2, padding: 2, textAlign: 'center', display: "flex", flexDirection: "column", alignItems: "center" }}>
              {/* Imagen o Avatar */}
              <Avatar
                alt={item.nombre}
                src={item.imgSrc}
                sx={{
                  width: 90,
                  height: 90,
                  marginBottom: 2,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  overflow: 'hidden', // Asegura que el contenido se recorte
                  border: '2px solid #ccc', // Opcional: borde para destacar el círculo
                  img: {
                    objectFit: 'cover', // Ajusta la imagen para cubrir el área del círculo
                  },
                }}
              />

              {/* Descripción */}
              <Typography variant="body1" sx={{ marginBottom: 1, width: {xs: "90%", md: "70%"}, textAlign: "center"}}>
                {item.descripcion}
              </Typography>

              {/* Nombre */}
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {item.nombre}
              </Typography>

              {/* Cargo u otro subtítulo */}
              <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom: 2 }}>
                {item.cargo}
              </Typography>

              {/* Botón de enlace */}
              {item.enlace && (
                <a href={item.enlace} target="_blank" rel="noopener noreferrer">
                  <Button variant="contained" color="primary" sx={{ marginTop: 1 }}>
                    {verMasTexto}
                  </Button>
                </a>
              )}
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default SliderReutilizable;
