import React from "react";
import { Box } from "@mui/material";
import ConjuntoDeTextoSection from "../../../../components/texto/cuerpoDeSecciones/conjuntoDeTexto"; 
import VideoPersonalizado from "../../../../components/videos/videosYoutube/videoPersonalizado"; 

const SectionVideo = ({ iconText, title, subtitle, videoUrl, linkUrl, linkText }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // En móviles el video irá abajo, en escritorio se mantendrá en la misma posición
        alignItems: "center",
        gap: "50px",
      }}
    >
      <Box sx={{ width: { xs: "100%", md: "50%" } }}>
        <ConjuntoDeTextoSection
          iconText={iconText}
          title={title}
          subtitle={subtitle}
          linkUrl={linkUrl}
          linkText={linkText}
          titleVariant="h4"
          subtitleVariant="body1"
          titleColor="#211E26"
          subtitleColor="text.secondary"
          backgroundColor="#fff"
        />
      </Box>
      <Box sx={{ width: { xs: "100%", md: "50%" } }}>
        <VideoPersonalizado
          url={videoUrl}
          width="100%"
          height="400px"
          borderRadius={8}
        />
      </Box>
    </Box>
  );
};

export default SectionVideo;
