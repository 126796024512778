import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom"; 

const AgendaUnaConsulta = ({
  text = "Asesoria Personalizada",
  borderColor = "#F21C63", 
  hoverColor = "#bf0a46",
  fontSize = "16px",
  fontWeight = "500",
  to,
  onClick,
  ...rest
}) => {
  return (
    <Link to={to} style={{ textDecoration: "none" }}>  
      <Button
        sx={{
          width: "100%",
          backgroundColor: "transparent",  
          border: `2px solid ${borderColor}`,
          color: borderColor, 
          borderRadius: "5px",
          padding: "8px 30px",
          fontSize: fontSize,
          fontWeight: `${fontWeight} !important`,
          boxShadow: "none",
          transition: "all 0.3s ease",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "transparent",  
            borderColor: hoverColor,  
            color: hoverColor, 
          },
        }}
        onClick={onClick}
        {...rest}
      >
        {text}
      </Button>
    </Link>
  );
};

export default AgendaUnaConsulta;
