import React from "react";
import { Card, CardContent, CardMedia, Typography, Box, Button } from "@mui/material";
import ArrowOutward from "@mui/icons-material/ArrowOutward";

const CustomCard = ({ title, description, category, image, link, logo, circleBackground }) => {
  return (
    <Card
  sx={{
    maxWidth: 380,
    borderRadius: "15px",
    background: "#F9F9FB", // Fondo gris claro
    margin: "20px auto",
    display: "flex",
    flexDirection: "column",
    height: "580px",
    cursor: "pointer",
    transform: "translateY(-10px)",
    boxShadow: "0 15px 25px rgba(0, 0, 0, 0.1)",
    transition:
      "transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease",
    "&:hover": {
      background: "#ECEAEF", // Fondo hover morado suave
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      transform: "translateY(0)",
    },
  }}
>
  <Box
    sx={{
      position: "relative",
      width: "100%",
      height: "220px",
      borderRadius: "15px 15px 0 0",
      overflow: "hidden",
    }}
  >
    <CardMedia
      component="img"
      image={image}
      alt={title}
      sx={{
        objectFit: "cover",
        transition: "opacity 0.3s ease",
        height: "100%",
        width: "100%",
      }}
    />
  </Box>
  <Box
    sx={{
      position: "absolute",
      top: "160px",
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 10,
      width: "100px",
      height: "100px",
      borderRadius: "50%",
      background: circleBackground || "#6C4DE2", // Fondo morado claro
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
    }}
  >
    <img
      src={logo}
      alt="Logo"
      style={{
        width: "70%",
        height: "70%",
        objectFit: "contain",
      }}
    />
  </Box>

  <CardContent>
    <Typography
      variant="body1"
      sx={{
        mb: 1,
        mt: 6,
        color: "#3B207F", // Morado oscuro
      }}
    >
      {category}
    </Typography>
    <Typography
      variant="h6"
      sx={{
        mb: 2,
        color: "#3B207F", // Morado oscuro
        fontWeight: "500",
        fontSize: "1.2rem",
      }}
    >
      {title}
    </Typography>
    <Typography
      variant="body2"
      sx={{
        mb: 2,
        color: "#6C6C6C", // Gris neutro
      }}
    >
      {description}
    </Typography>
  </CardContent>

  <Box sx={{ padding: "16px", display: "flex", justifyContent: "center" }}>
    <Button
      component="a"
      href={link}
      target="_blank"
      variant="contained"
      sx={{
        backgroundColor: "#6C4DE2", // Morado claro
        color: "#ffffff",
        borderRadius: "20px",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#F21C63", // Hover fucsia
          color: "#ffffff",
        },
      }}
    >
      Leér más
      <ArrowOutward sx={{ fontSize: "18px", marginLeft: "10px" }} />
    </Button>
  </Box>
</Card>
  );
};

export default CustomCard;
