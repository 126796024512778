import React from "react";
import { Box } from "@mui/material";
import ImagenPersonalizada from "../../../../components/imagenes/Imagen/imagenPage";
import ConjuntoDeTextoSection from "../../../../components/texto/cuerpoDeSecciones/conjuntoDeTexto";

// Componente reutilizable SeccionCuerpoImagen
const SeccionCuerpoImagen = ({ data }) => {
  const {
    imgSrc,
    imgAlt,
    iconText,
    title,
    subtitle,
    linkUrl,
    linkText,
    titleVariant = "h4",
    subtitleVariant = "body1",
    titleColor = "#211E26",
    subtitleColor = "text.secondary",
    backgroundColor = "#fff",
    order = "text-first", // 'text-first' o 'image-first'
  } = data;

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: { xs: "column", md: order === "text-first" ? "row" : "row-reverse" },
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        backgroundColor: backgroundColor,
      }}
    >
      {/* Contenedor de la imagen */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ImagenPersonalizada
          src={imgSrc}
          alt={imgAlt}
          width="100%"
          height="auto"
          borderRadius="16px"
          sx={{ maxWidth: "100%", maxHeight: "400px" }}
        />
      </Box>
      {/* Contenedor del texto */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <ConjuntoDeTextoSection
          iconText={iconText}
          title={title}
          subtitle={subtitle}
          linkUrl={linkUrl}
          linkText={linkText}
          titleVariant={titleVariant}
          subtitleVariant={subtitleVariant}
          titleColor={titleColor}
          subtitleColor={subtitleColor}
        />
      </Box>
    </Box>
  );
};

export default SeccionCuerpoImagen;
