import React from "react";
import { Box, Grid, Typography, Card, CardMedia, CardContent, Container } from "@mui/material";

// Imágenes
import Eduina from "../../../assets/eduina.png";
import Sergio from "../../../assets/sergio.png";
import Nicolas from "../../../assets/Nicolas.png";

const TeamMember = ({ name, position, image }) => {
  return (
    <Card
      sx={{
        maxWidth: 300,
        borderRadius: "16px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease",
        "&:hover": {
          transform: "scale(1.05)",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
        },
        overflow: "hidden",
        backgroundColor: "#fff",
      }}
    >
      <CardMedia
        component="img"
        height="250"
        image={image}
        alt={name}
        sx={{
          objectFit: "cover",
          borderRadius: "16px 16px 0 0",
        }}
      />
      <CardContent sx={{ textAlign: "center", padding: "20px" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#211E26" }}>
          {name}
        </Typography>
        <Typography variant="body2" sx={{ color: "#666666" }}>
          {position}
        </Typography>
      </CardContent>
    </Card>
  );
};

const TeamSection = () => {
  const team = [
    {
      name: "EDUINA RIVAS",
      position: "DIRECTORA DE MARKETING",
      image: Eduina,
    },
    {
      name: "SERGIO CEPEDA",
      position: "DIRECTOR DE TECNOLOGÍA",
      image: Sergio,
    },
    {
      name: "NICOLAS AYALA",
      position: "DESARROLLADOR DE SOFTWARE",
      image: Nicolas,
    },
  ];

  return (
    <Container>
      <Box sx={{ paddingTop: "100px", textAlign: "center", paddingBottom: "50px" }}>
        <Typography variant="h6" sx={{ mb: 2, color: "#211E26" }}>
          MIEMBROS DEL EQUIPO
        </Typography>
        <Typography
          variant="h4"
          sx={{
            color: "#211E26",
            mb: 4,
            fontSize: "2.5rem",
            fontWeight: "500",
          }}
        >
          Saluda a nuestro equipo
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {team.map((member, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <TeamMember name={member.name} position={member.position} image={member.image} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default TeamSection;
