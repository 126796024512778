import React from "react";
import { Card, CardContent, Typography, Button, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom"; // Importa el componente Link

const CardPrecio = ({
  titulo,
  precioReal,
  precioDescuento,
  descripcion,
  beneficios,
  to
}) => {
  return (
    <Card
      sx={{
        width: "100%", // La tarjeta ocupa el 100% del ancho del contenedor
        margin: "auto", // Centra la tarjeta
        padding: 2,
        boxShadow: 3,
        borderRadius: 2,
        border: "none",
      }}
    >
      <CardContent>
        {/* Título */}
        <Typography variant="h5" align="center" gutterBottom>
          {titulo}
        </Typography>

        {/* Precio real */}
        <Typography
          variant="h4"
          align="center"
          color="primary"
          sx={{ fontWeight: "semibold" }}
        >
          ${precioReal} <span style={{ color: "gray", fontSize: "0.5em" }}>USD</span>
        </Typography>

        {/* Precio con descuento tachado */}
        <Typography
          variant="body1"
          align="center"
          color="textSecondary"
          sx={{
            textDecoration: "line-through",
            marginBottom: 2,
          }}
        >
          ${precioDescuento}*
        </Typography>

        {/* Descripción */}
        <Typography
          variant="body2"
          align="center"
          color="textSecondary"
          gutterBottom
        >
          {descripcion}
        </Typography>

        {/* Botón con enlace */}
        <Link to={to} style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ marginY: 2 }}
          >
            Contratar servicio
          </Button>
        </Link>

        {/* Lista de beneficios */}
        <List>
          {beneficios.map((beneficio, index) => (
            <ListItem key={index} disableGutters>
              <ListItemIcon>
                <CheckCircleIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={beneficio} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default CardPrecio;
