import React from "react";
import { Grid } from "@mui/material"; // Importa Grid
import ServiceCard from "../../components/cards/cardDeServicios/cardDeServicios";

const services = [
  { 
    link: "/desarrollo-de-software-a-medida",
    image: "./images/desarrollodesoftware.png",
    title: "DESARROLLO DE SOFTWARE A MEDIDA EFICIENTE",
    description:
      "Creamos soluciones de software adaptadas a las necesidades de tu negocio, mejorando la eficiencia y reduciendo costos operativos para impulsar tu competitividad.",
  },
  { 
    link: "/marketing-de-contenidos",
    image: "./images/marketingContenidos.png",
    title: "ESTRATEGIAS EFECTIVAS DE MARKETING DE CONTENIDOS",
    description:
      "Generamos contenido que posiciona tu marca, atrae tráfico calificado y mejora tu visibilidad en buscadores, impulsando tus conversiones de manera efectiva.",
  },
  { 
    link: "/paginas-web-de-alta-conversion",
    image: "./images/desarrollodesoftware.png",
    title: "DESARROLLO DE PÁGINAS WEB DE ALTA CONVERSIÓN",
    description:
      "Diseñamos sitios web optimizados para atraer clientes y maximizar las conversiones, asegurando una experiencia atractiva y eficiente para el usuario.",
  },
  { 
    link: "/campañas-publicitarias",
    image: "./images/marketingContenidos.png",
    title: "CAMPAÑAS PUBLICITARIAS PARA MAXIMIZAR RESULTADOS",
    description:
      "Aumenta tu alcance con campañas publicitarias personalizadas que atraen a tu audiencia ideal y maximizan cada inversión publicitaria.",
  },
  {
    link: "/aplicaciones-moviles-a-medida",
    image: "./images/DisenoYdesarrollo.png",
    title: "DESARROLLO DE APLICACIONES MÓVILES A MEDIDA",
    description:
      "Creamos aplicaciones móviles a la medida que conectan tu negocio con los usuarios de forma eficaz, mejorando la interacción y la lealtad.",
  },
  {
    link: "/campañas-de-email-marketing",
    image: "./images/marketingContenidos.png",
    title: "CAMPAÑAS DE EMAIL MARKETING EFECTIVAS",
    description:
      "Conecta con tu audiencia a través de campañas de email marketing efectivas que convierten suscriptores en clientes leales.",
  },
];



const ServiceList = () => {
  return (
    <Grid container spacing={2} justifyContent="center" sx={{ padding: {xs: "20px 10px", md: "20px 60px"} }}>
      {services.map((service, index) => (
        <Grid item key={index} xs={12} sm={6} md={4} display="flex" justifyContent="center">
          <ServiceCard
            title={service.title}
            description={service.description}
            image={service.image} // Asegúrate de que la ruta sea correcta
            link={service.link}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ServiceList;
