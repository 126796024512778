import React from "react";
import { Box } from "@mui/material"; // Asegúrate de importar Box de MUI
import UneteAComunidadLayout from "../components/botones/comunidades/internasInformativas/comunidades";

const ComunidadDeComercio = () => {
  const title = "¡Únete a Nuestra Comunidad de Comercio!";
  const description =
    "Forma parte de un grupo exclusivo de WhatsApp donde compartimos información relevante sobre comercio electrónico, estrategias de ventas, y técnicas avanzadas para ayudarte a expandir tu negocio en línea. Aprende de expertos, resuelve tus dudas y conecta con emprendedores y empresarios que comparten tus intereses. ¡No te pierdas las oportunidades que tenemos para ti!";
  const whatsappLink = "https://chat.whatsapp.com/CvZdvBz7Gpb3ubsxY5dGRF"; // Reemplaza con el enlace correcto de WhatsApp
  const imageUrl = "/images/comunidadDeComercio.jpg"; // Reemplaza con la imagen adecuada

  return (
    <Box>
      <UneteAComunidadLayout
        title={title}
        description={description}
        whatsappLink={whatsappLink}
        imageUrl={imageUrl}
      />
    </Box>
  );
};

export default ComunidadDeComercio;
