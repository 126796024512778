import React from "react";
import { Box, Typography, Link } from "@mui/material";

const ConjuntoDeTextoSection = ({
  title,
  subtitle,
  titleVariant = "h6",
  subtitleVariant = "body2",
  titleColor = "#211E26",
  subtitleColor = "text.secondary",
  backgroundColor = "#fff",
  linkUrl,
  linkText,
  linkColor = "primary", 
  ...rest
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundColor,
        borderRadius: 8,
        padding: 2,
      }}
      {...rest}
    >
      {/* Título y subtítulo */}
      <Box>
        <Typography
          variant={titleVariant}
          component="h2"
          sx={{
            color: titleColor,
            marginBottom: 2,
            fontSize: "2.5rem",
            fontWeight: "500",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant={subtitleVariant}
          sx={{
            color: subtitleColor,
            fontSize: "1.2rem",
            marginBottom: 4,
          }}
        >
          {subtitle}
          {/* Si se pasa linkUrl y linkText, se mostrará el enlace */}
          {linkUrl && linkText && (
            <Link
              href={linkUrl}
              sx={{
                color: linkColor,
                textDecoration: "none", 
                "&:hover": {
                  textDecoration: "none", 
                },
              }}
            >
              {linkText}
            </Link>
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default ConjuntoDeTextoSection;
