import React from "react";
import {
  Grid,
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";

const CardModernizacion = () => {
  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
        md={4}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
        >
          Modernización de la primera línea
        </Typography>
        <Typography
          variant="body1"
          align="center"
          gutterBottom
        >
          Descubre cómo Google Workspace apoya a los trabajadores de primera
          línea con herramientas modernas.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
      >
        <Card>
          <CardMedia
            component="img"
            height="200"
            image="/path/to/image1.jpg"
            alt="Imagen de la primera línea"
          />
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
            >
              Transforma la primera línea con Google Workspace y Gemini
            </Typography>
            <Button
              variant="contained"
              href="/path/to/manual"
            >
              Leer el manual
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
      >
        <Card>
          <CardMedia
            component="img"
            height="200"
            image="/path/to/image2.jpg"
            alt="Imagen de la primera línea"
          />
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
            >
              Le damos forma al futuro del trabajo para los trabajadores de
              primera línea
            </Typography>
            <Button
              variant="contained"
              href="/path/to/video"
            >
              Mirar el video
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
      >
        <Card>
          <CardMedia
            component="img"
            height="200"
            image="/path/to/image3.jpg"
            alt="Imagen de la primera línea"
          />
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
            >
              Empodere a sus equipos para transformar las operaciones sin
              necesidad de tecnología complicada
            </Typography>
            <Button
              variant="contained"
              href="/path/to/guide"
            >
              Ver la guía
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
      >
        <Card>
          <CardMedia
            component="img"
            height="200"
            image="/path/to/image3.jpg"
            alt="Imagen de la primera línea"
          />
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
            >
              Empodere a sus equipos para transformar las operaciones sin
              necesidad de tecnología complicada
            </Typography>
            <Button
              variant="contained"
              href="/path/to/guide"
            >
              Ver la guía
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
      >
        <Card>
          <CardMedia
            component="img"
            height="200"
            image="/path/to/image3.jpg"
            alt="Imagen de la primera línea"
          />
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
            >
              Empodere a sus equipos para transformar las operaciones sin
              necesidad de tecnología complicada
            </Typography>
            <Button
              variant="contained"
              href="/path/to/guide"
            >
              Ver la guía
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CardModernizacion;
