// components/ServicioCard.js
import React from "react";
import { Box } from "@mui/material";
import EncabezadoIzquierdo from "../../../../components/texto/cuerpoIzquierdo/textoIzquierdo";
import ImagenPersonalizada from "../../../../components/imagenes/Imagen/imagenPage";
import SocialDistanceIcon from "@mui/icons-material/SocialDistance";
import AsesoriaPersonalizada from "../../../../components/botones/botonAsesoria";
import AgendaUnaConsulta from "../../../../components/botones/agendaUnaConsulta";

const PortadaDeServicio = ({ title, subtitle, imgSrc, imgAlt, href, to }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        gap: "40px", // Espaciado entre los elementos
      }}
    >
      {/* Servicio 1 - EncabezadoIzquierdo */}
      <Box
        sx={{
          width: { xs: "100%", md: "40%" },
          padding: "0 16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <EncabezadoIzquierdo
          icon={SocialDistanceIcon}
          iconText="Social Media"
          title={title}
          subtitle={subtitle}
          backgroundColor="#ffffff"
          iconColor="#4285F4"
          iconSize={30}
        />
        <Box
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "flex-start",
            gap: "16px",
          }}
        >
          <AsesoriaPersonalizada
            text="Contacta ahora mismo"
            backgroundColor="#F21C63"
            hoverColor="#bf0a46"
            fontSize="18px"
            fontWeight="600"
            to={href}
          />
          <AgendaUnaConsulta
            text="Agenda tu consulta"
            borderColor="#F21C63"
            hoverColor="#0B8DB5"
            fontSize="18px"
            fontWeight="600"
            to={to}
          />
        </Box>
      </Box>

      {/* Imagen - ImagenPersonalizada */}
      <ImagenPersonalizada
        src={imgSrc}
        alt={imgAlt}
        width="100%"
        height="auto"
        borderRadius="16px"
        sx={{
          maxWidth: "550px",
          maxHeight: "400px",
          width: { xs: "100%", md: "auto" },
          display: {xs: "none", md: "flex"}, 
        }}
      />
    </Box>
  );
};

export default PortadaDeServicio;
