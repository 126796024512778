import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu"; // Icono de menú móvil
import CloseIcon from "@mui/icons-material/Close"; // Icono de cerrar
import NavegacionInternaServicios from "./subNavMobile/subnavegacionServicios";
import NavegacionInternaSoluciones from "./subNavMobile/subnavegacionSoluciones";
import NavegacionInternalSectores from "./subNavMobile/subnavegacionSectores";
import NavegacionInternalRecursos from "./subNavMobile/subnavegacionRecursos";
import UneteALaComunidad from "../../components/botones/comunidades/botónComunidad";
import AsesoriaPersonalizada from "../../components/botones/botonAsesoria";
import { Link } from "react-router-dom"; // Importar Link

const Servicios = () => <NavegacionInternaServicios />;
const Soluciones = () => <NavegacionInternaSoluciones />;
const Sectores = () => <NavegacionInternalSectores />;
const Recursos = () => <NavegacionInternalRecursos />;

const MenuMobile = () => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentComponent, setCurrentComponent] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false); // Estado para abrir y cerrar el Drawer

  const location = useLocation(); // Detecta la ubicación actual
  const navigate = useNavigate(); // Navega entre rutas

  const menuItems = [
    { label: "Servicios", component: <Servicios />, path: null },
    { label: "Soluciones", component: <Soluciones />, path: null },
    { label: "Sectores", component: <Sectores />, path: null },
    { label: "Recursos", component: <Recursos />, path: null },
    { label: "Blog", component: null, path: "https://blog.hitpoly.com/" },
  ];

  // Actualiza el estado seleccionado basado en la ruta
  useEffect(() => {
    const currentIndex = menuItems.findIndex(
      (item) => item.path === location.pathname
    );
    setSelectedItem(currentIndex !== -1 ? currentIndex : null);
  }, [location.pathname]);

  const closeOverlay = () => {
    setCurrentComponent(null);
    setSelectedItem(null);
  };

  const handleMenuItemClick = (item, index) => {
    if (item.path) {
      if (item.path.startsWith("http")) {
        // Enlace externo
        window.open(item.path, "_blank", "noopener,noreferrer");
      } else {
        // Navegación interna
        navigate(item.path);
      }
      setSelectedItem(index); // Selecciona el ítem
    } else {
      setSelectedItem(index); // Selecciona el ítem
      setCurrentComponent(item.component); // Establece el componente actual
    }
    setOpenDrawer(false); // Cierra el drawer
  };

  return (
    <Box sx={{ zIndex: "99999" }}>
      {/* Botón para abrir el Drawer en dispositivos móviles */}
      <Box
        sx={{
          display: { xs: "block", md: "none" },
          padding: "10px",
          marginLeft: "10px",
        }}
      >
        <IconButton
          onClick={() => setOpenDrawer(true)}
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>

        {/* Logo a la derecha del icono */}
        <Link to="/" style={{ textDecoration: "none" }}> {/* Envolver el logo con Link */}
          <img
            src="/images/logo-hitpoly.png"
            alt="Hitpoly Logo"
            style={{ width: "150px", height: "40px", marginLeft: "5px" }}
          />
        </Link>
      </Box>

      {/* Drawer (menú lateral) */}
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: 240,
            backgroundColor: "#fff",
            boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <Box
          sx={{
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Menú</Typography>
          <IconButton onClick={() => setOpenDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {menuItems.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => handleMenuItemClick(item, index)}
              sx={{
                padding: "10px",
                backgroundColor:
                  selectedItem === index ? "#F1F1F1" : "transparent",
              }}
            >
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>

        {/* Botones adicionales en el menú móvil */}
        <Box sx={{ marginTop: "20px", padding: "0 20px" }}>
          <Box>
          <AsesoriaPersonalizada
            text="Asesoria Personalizada"
            to="https://meetings.hubspot.com/hitpoly?uuid=34a2179d-26a9-46dc-9eae-98738ae67fae"
          />
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <UneteALaComunidad />
          </Box>
        </Box>
      </Drawer>

      {/* Overlay y Componente dinámico */}
      {currentComponent && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 99,
          }}
          onClick={closeOverlay}
        />
      )}

      {currentComponent && (
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            left: "0",
            width: "100%",
            zIndex: 1000,
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "10px",
              right: "20px",
              width: "30px",
              height: "30px",
              backgroundColor: "#F21C63",
              color: "#fff",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              zIndex: 1001,
            }}
            onClick={closeOverlay}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                lineHeight: 1,
                userSelect: "none",
              }}
            >
              X
            </Typography>
          </Box>
          <Box
            sx={{
              maxWidth: "100%",
              padding: "20px",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {currentComponent}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MenuMobile;
