import { Box, Typography, Button } from "@mui/material";

const OfertaEspecial = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
        padding: "24px",
        width: "68%",
        borderRadius: "8px",
        margin: " auto", // Centrar horizontalmente
      }}
    >
      {/* Título */}
      <Typography
        variant="h4"
        component="div"
        gutterBottom
      >
        Por tiempo limitado, los clientes de Enterprise pueden recibir precios
        especiales en las soluciones de Google Workspace Frontline.
      </Typography>

      {/* Descripción */}
      <Typography
        variant="body2"
        color="text.secondary"
        gutterBottom
        fontSize="1.3rem"
      >
        Para conocer los precios y los criterios de elegibilidad, comunícate con
        nuestro equipo de Ventas o con tu revendedor de Google Workspace.
      </Typography>

      {/* Botón */}
      <Button
        variant="contained"
        color="primary"
        sx={{
          marginTop: "3.5rem",
          padding: "12px 24px",
          textTransform: "none",
          fontWeight: "bold", // Mantener el texto normal
        }}
      >
        Comunicarse con Ventas
      </Button>
    </Box>
  );
};

export default OfertaEspecial;
