import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Box, Grid, styled } from "@mui/material";
import {
  FaRocket,
  FaCheckCircle,
  FaChartLine,
  FaCogs,
  FaArrowAltCircleUp,
  FaTrophy,
} from "react-icons/fa"; // Importamos iconos

const StepCard = styled(Card)`
  background-color: #ffffff;
  color: #333333;
  padding: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  height: 300px;
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #e8e8e8; /* Suavizado de color de fondo al hacer hover */
    color: #333333; /* Mantiene el texto en color oscuro */
    transform: scale(1.05); /* Efecto de agrandar */
  }
  &:hover > .step-title,
  &:hover > .step-number,
  &:hover > .step-description,
  &:hover > .icon-container {
    color: #333333; /* Cambio de color a oscuro en hover */
  }
`;

const StepTitle = styled(Typography)`
  font-size: 16px;
  font-weight: bold;
  color: inherit; /* Inicia con el color original */
  text-align: left;
  &.step-title {
    /* Añade la clase personalizada */
    color: inherit;
  }
`;

const StepNumber = styled(Typography)`
  font-size: 40px;
  font-weight: bold;
  color: #6a0dff;
  margin-right: 10px;
  &.step-number {
    /* Añade la clase personalizada */
    color: inherit;
  }
`;

const StepDescription = styled(Typography)`
  margin-top: 20px;
  font-size: 14px;
  color: #555555;
  flex-grow: 1; /* Para que ocupe el espacio disponible */
  &.step-description {
    /* Añade la clase personalizada */
    color: inherit;
  }
`;

const IconContainer = styled(Box)`
  font-size: 40px;
  margin-bottom: 20px;
  color: inherit;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &.icon-container {
    /* Añade la clase personalizada */
    color: inherit;
  }
`;

const StepGrid = () => {
  return (
    <Box
      sx={{
        paddingTop: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: { xs: 5, lg: 12 },
        width: { lg: "98vw" },
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            color: "#6a0dff",
            fontSize: { xs: "1.125rem", sm: "1.125rem", md: "1.25rem" },
          }}
        >
          ¿COMO TRABAJAMOS?
        </Typography>

        <Typography
          variant="h3"
          sx={{
            mb: 3,
            color: "#333333",
            fontSize: "2.5rem",
            fontWeight: "500",
            width: { xs: "90%", lg: "70%" },
          }}
        >
          Nuestro proceso para marcas y empresas distribuido en etapas
        </Typography>
      </Box>
      <Grid
        container
        spacing={1}
        sx={{
          width: "90%",
          gap: 7,
          marginTop: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {[...Array(6)].map((_, index) => (
          <Grid item xs={12} sm={6} md={5} lg={5} key={index}>
            <StepCard
              sx={{
                padding: "20px 50px",
                height: { xs: "auto", lg: "300px" },
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <IconContainer className="icon-container">
                  {index === 0 && <FaRocket />}
                  {index === 1 && <FaCheckCircle />}
                  {index === 2 && <FaChartLine />}
                  {index === 3 && <FaCogs />}
                  {index === 4 && <FaArrowAltCircleUp />}
                  {index === 5 && <FaTrophy />}
                </IconContainer>
                <Box sx={{ flex: 1 }}>
                  <StepNumber className="step-number" variant="h2">{`0${
                    index + 1
                  }`}</StepNumber>
                  <StepTitle className="step-title">
                    {index === 0 &&
                      "Consulta Inicial - Conectando tus Objetivos con Soluciones"}
                    {index === 1 &&
                      "Propuesta Personalizada - Diseñando tu Camino al Éxito"}
                    {index === 2 &&
                      "Planificación Estratégica - Estrategias que Impulsan Resultados"}
                    {index === 3 &&
                      "Implementación - De la Estrategia a la Acción"}
                    {index === 4 &&
                      "Optimización del Proceso Comercial - Crecimiento Sostenible y Escalable"}
                    {index === 5 &&
                      "Resultados y Escalabilidad - Maximiza el Éxito a Largo Plazo"}
                  </StepTitle>
                </Box>
              </Box>
              <StepDescription className="step-description">
                {index === 0 &&
                  "Comenzamos con una evaluación profunda de tus necesidades y objetivos. Nuestra consulta inicial nos permite comprender la esencia de tu negocio para crear soluciones personalizadas que alineen tu visión con el futuro."}
                {index === 1 &&
                  "Te ofrecemos una propuesta personalizada que refleja nuestras soluciones, diseñadas específicamente para ti. Este plan incluye tácticas y estrategias enfocadas en alcanzar tus objetivos de manera efectiva y a medida."}
                {index === 2 &&
                  "Una vez aprobada la propuesta, pasamos a la planificación estratégica. Aquí definimos cada detalle del camino a seguir, estableciendo metas claras y procesos eficaces para asegurar que cada paso se enfoque en maximizar tus resultados."}
                {index === 3 &&
                  "Con el plan en mano, comenzamos la implementación. Nuestro equipo se encarga de ejecutar todas las tácticas diseñadas, gestionando cada detalle para garantizar que la estrategia se lleve a cabo con precisión y eficacia."}
                {index === 4 &&
                  "La optimización continua es clave para el éxito a largo plazo. Refinamos el proceso comercial, mejoramos cada paso en el Buyer Journey y aseguramos que cada interacción sea valiosa, eficiente y relevante para tus clientes."}
                {index === 5 &&
                  "Finalmente, evaluamos los resultados y buscamos nuevas oportunidades para escalar el éxito. Refinamos las estrategias y buscamos formas de aumentar el impacto, asegurando que tu negocio siga creciendo con éxito a largo plazo."}
              </StepDescription>
            </StepCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default StepGrid;
