import React from "react";
import { Box } from "@mui/material";
import PortadaDeServicio from "./secciones/portada.jsx";
import NavegationForSection from "../../../navbar/navSection/navbar.jsx";
import SectionVideo from "./secciones/sectionVideo.jsx";
import AcordeonInteligente from "./secciones/acordeonInteligenteVideo.jsx";
import AcordeonInteligenteImagen from "./secciones/acordeonInteligenteImagen.jsx";
import CartasResultados from "./secciones/cartasResultado.jsx";
import SeccionCuerpoImagen from "./secciones/cuerpoConImagen.jsx";
import SliderTestimonios from "./secciones/sliderTestimonios.jsx";
import SeccionConTituloYAcordeon from "./secciones/preguntasYRespuestas.jsx";
import LlamadoALaAccion from "./secciones/llamadoALaAccionFinal.jsx";
import Footer from "../../../footer/page.jsx";

const LayoutDynamicPage = ({
  portadaProps,
  menuItems,
  sectionVideoProps,
  acordeonInteligenteProps,
  acordeonInteligenteImagenProps,
  cartasResultadosProps,
  seccioneCuerpoImagenData,
  datosTestimonios,
  adicionalescartasResultadosProps,
  SeccionConTituloYAcordeonProps,
  llamadoAlaAccionProps,
}) => {

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: "#fff",
        minHeight: "100vh",
        padding: 0,
        marginTop: { xs: 5, md: 10 },
      }}
    >
      <Box sx={{ padding: "16px", width: "100%" }}>
        <PortadaDeServicio {...portadaProps} />
      </Box>
      <Box
        sx={{
          padding: "16px 24px",
          display: { xs: "none", md: "flex" },
          margin: "50px 0px",
          justifyContent: "center",
          alignItems: "center",
          position: "sticky",
          top: 80,
          zIndex: 100,
        }}
      >
        <NavegationForSection menuItems={menuItems} />
      </Box>
      <Box>
        <Box
          id="seccio1"
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: { xs: "0px 20px", md: "50px 150px" },
            paddingTop: { xs: "50px", md: "80px" },
          }}
        >
          <SectionVideo {...sectionVideoProps} />
        </Box>
        <Box
          id="seccio2"
          sx={{
            backgroundColor: "#fff",
            padding: { xs: "0px 20px", md: "50px 150px" },
            paddingTop: { xs: "50px", md: "80px" },
          }}
        >
          <AcordeonInteligente {...acordeonInteligenteProps} />
        </Box>
        <Box
          id="seccio3"
          sx={{
            backgroundColor: "#fff",
            padding: { xs: "0px 20px", md: "0px 150px" },
            paddingTop: { xs: "50px", md: "80px" },
          }}
        >
          <AcordeonInteligenteImagen {...acordeonInteligenteImagenProps} />
        </Box>
        <Box
          id="seccio4"
          sx={{
            display: "flex",
            paddingTop: { xs: "50px", md: "80px" },
          }}
        >
          <CartasResultados {...cartasResultadosProps} />
        </Box>
        <Box
          id="seccio5"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: "0px 20px", md: "50px 150px" },
            paddingTop: { xs: "50px", md: "100px" },
            gap: { xs: 0, md: 4 }, // Espaciado entre las secciones
          }}
        >
          {seccioneCuerpoImagenData.map((seccion, index) => {
            return (
              <SeccionCuerpoImagen
                key={index} // Clave única para cada elemento renderizado
                data={{ ...seccion }}
              />
            );
          })}
        </Box>
        <Box
          id="seccio6"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: "0px 20px", md: "0px 150px" },
            paddingTop: { xs: "50px", md: "50px" },
            gap: 4, // Espaciado entre las secciones
          }}
        >
          <SliderTestimonios {...datosTestimonios} />
        </Box>
        <Box
          id="seccio7"
          sx={{
            display: "flex",
            paddingTop: { xs: "50px", md: "80px" },
          }}
        >
          <CartasResultados {...adicionalescartasResultadosProps} />
        </Box>
        <Box
          id="seccio8"
          sx={{
            display: "flex",
            padding: { xs: "0px 20px", md: "50px 250px" },
            paddingTop: { xs: "50px", md: "100px" },
          }}
        >
          <SeccionConTituloYAcordeon {...SeccionConTituloYAcordeonProps} />
        </Box>
        <Box
          id="seccio9"
          sx={{
            display: "flex",
            padding: { xs: "0px 20px", md: "0px 150px" },
            paddingTop: { xs: "50px", md: "50px" },
            marginBottom: { xs: 5, md: 10 },
          }}
        >
          <LlamadoALaAccion {...llamadoAlaAccionProps} />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default LayoutDynamicPage;
