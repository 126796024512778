import React from "react";
import { Box } from "@mui/material";
import TituloSubtituloCentro from "../../../../components/texto/TituloSubtituloCentro/cuerpoEnElCentro";
import CardBeneficios from "../../../../components/cards/cardBeneficios/beneficios";

const CartasResultados = ({
  title,
  subtitle,
  titleColor = "#211E26",
  subtitleColor = "text.secondary",
  containerStyles = {},
  contentStyles = {},
  benefits = [], 
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        textAlign: "center", 
        ...containerStyles, 
      }}
    >
      {/* Título y subtítulo */}
      <TituloSubtituloCentro
        title={title}
        subtitle={subtitle}
        titleColor={titleColor}
        subtitleColor={subtitleColor}  
      />

      {/* Contenedor principal del contenido */}
      <Box
        sx={{
          display: "flex",
          mt: "80px",
          flexDirection: "row", 
          alignItems: "center", 
          justifyContent: "center", 
          gap: 5, 
          flexWrap: "wrap", 
          width: "100%",
          ...contentStyles, 
        }}
      >
        {benefits.map((benefit, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: 300, 
                ...benefit.cardStyles, 
              }}
            >
              <CardBeneficios
                image={benefit.image}
                title={benefit.title}
                description={benefit.description}
                imageHeight={benefit.imageHeight}
                imageWidth={benefit.imageWidth}
                titleStyles={benefit.titleStyles} 
                descriptionStyles={benefit.descriptionStyles} 
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CartasResultados;
