import React, { useState } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AcordeonPreguntasFrecuentes = ({ questions = [] }) => {
  const [expanded, setExpanded] = useState(null); // Estado para controlar qué acordeón está abierto

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null); // Cambia el estado según el acordeón que se abre
  };

  return (
    <Box sx={{ width: "100%" }}>
      {questions.map((question, index) => (
        <Accordion
          key={index}
          disableGutters
          elevation={0}
          square
          expanded={expanded === index} // Controla si el acordeón está expandido
          onChange={handleChange(index)} // Maneja el cambio de expansión
          sx={{
            borderBottom: "1px solid #ddd",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            sx={{
              minHeight: "80px",
              "& .MuiAccordionSummary-content": {
                margin: "12px 0",
              },
            }}
          >
            <Typography variant="body1">{question.question}</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "16px",
            }}
          >
            <Typography>{question.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default AcordeonPreguntasFrecuentes;
