import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const FAQSection = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        padding: "50px 20px",
        borderRadius: "10px",
      }}
    >
      <Box textAlign="center" sx={{ mb: 5 }}>
        <Typography
          variant="h6"
          sx={{ color: "#211E26", mb: 2, fontWeight: "500" }}
        >
          ALGUNAS RESEÑAS
        </Typography>
        <Typography
          variant="h3"
          sx={{
            color: "#211E26",
            fontSize: "2.5rem",
            fontWeight: "500",
          }}
        >
          Preguntas Frecuentes sobre HitPoly
        </Typography>
      </Box>

      <Accordion
        sx={{
          mb: 2,
          boxShadow: "none",
          borderTop: "1px solid rgba(33, 30, 38, 0.3)", // Borde sutil
          borderBottom: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontWeight: "bold", color: "#211E26" }}>
            ¿Qué es HitPoly y qué ofrece?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ color: "#211E26" }}>
            HitPoly es una agencia líder en tecnología y marketing estratégico
            que combina innovación avanzada y responsabilidad social para
            impulsar el éxito empresarial y contribuir al bienestar social.
            Ofrecemos soluciones personalizadas en tecnología y marketing para
            ayudar a las empresas a destacarse en un mundo competitivo y en
            constante cambio.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          mb: 2,
          boxShadow: "none",
          borderTop: "1px solid rgba(33, 30, 38, 0.3)", // Borde sutil
          borderBottom: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ fontWeight: "bold", color: "#211E26" }}>
            ¿Qué diferencia a HitPoly de otras empresas?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ color: "#211E26" }}>
            Lo que distingue a HitPoly es nuestro compromiso con la innovación
            disruptiva y el impacto social positivo. No solo ofrecemos
            soluciones tecnológicas y de marketing, sino que también
            priorizamos la sostenibilidad, la inclusión y el progreso humano en
            cada proyecto.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          mb: 2,
          boxShadow: "none",
          borderTop: "1px solid rgba(33, 30, 38, 0.3)", // Borde sutil
          borderBottom: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography sx={{ fontWeight: "bold", color: "#211E26" }}>
            ¿Cómo HitPoly contribuye al impacto social?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ color: "#211E26" }}>
            En HitPoly, integramos prácticas de responsabilidad social en cada
            aspecto de nuestras operaciones. Desde desarrollar soluciones que
            fomenten la equidad y la inclusión, hasta participar activamente en
            iniciativas comunitarias y sostenibles, trabajamos para hacer una
            diferencia positiva en el mundo.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          mb: 2,
          boxShadow: "none",
          borderTop: "1px solid rgba(33, 30, 38, 0.3)", // Borde sutil
          borderBottom: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography sx={{ fontWeight: "bold", color: "#211E26" }}>
            ¿Qué sectores puede atender HitPoly?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ color: "#211E26" }}>
            HitPoly atiende a una amplia gama de sectores, incluidos comercio
            electrónico, educación, salud, servicios legales y más. Nuestra
            experiencia nos permite ofrecer soluciones adaptadas a las
            necesidades específicas de cada industria.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          mb: 2,
          boxShadow: "none",
          borderTop: "1px solid rgba(33, 30, 38, 0.3)", // Borde sutil
          borderBottom: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography sx={{ fontWeight: "bold", color: "#211E26" }}>
            ¿Cómo puedo comenzar a trabajar con HitPoly?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ color: "#211E26" }}>
            ¡Es fácil comenzar! Simplemente contáctanos a través de nuestro
            formulario en línea o redes sociales, y uno de nuestros expertos se
            pondrá en contacto contigo para comprender tus necesidades y crear
            una solución personalizada para tu empresa.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default FAQSection;
