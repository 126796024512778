import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";  // Importa el Link de react-router-dom

const AsesoriaPersonalizada = ({
  text = "Asesoria Personalizada",
  backgroundColor = "#F21C63",
  hoverColor = "#bf0a46",
  fontSize = "16px",
  fontWeight = "500",
  to,
  onClick,
  ...rest
}) => {
  return (
    <Link to={to} style={{ textDecoration: "none" }}>  
      <Button
        sx={{
          width: "100%",
          backgroundColor: backgroundColor,
          color: "#fff",
          borderRadius: "5px",
          padding: "10px 30px",
          fontSize: fontSize,
          fontWeight: `${fontWeight} !important`,
          boxShadow: "none",
          transition: "all 0.3s ease",
          textTransform: "none",
          "&:hover": {
            backgroundColor: hoverColor,
          },
        }}
        onClick={onClick}
        {...rest}
      >
        {text}
      </Button>
    </Link>
  );
};

export default AsesoriaPersonalizada;
