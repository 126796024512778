import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";

const CustomMenuList = styled("ul")(() => ({
  margin: 0,
  padding: "5px 0",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "25px",
  gap: 10,
  position: "relative",
  width: "100%",
  listStyleType: "none",
}));

const EfectoSpan = styled("span")(({ width, left }) => ({
  position: "absolute",
  top: 0,
  left: `${left}px`,
  width: `${width}px`,
  height: "100%",
  background: "linear-gradient(45deg, #6F4CE0, #6F4CE0)",
  borderRadius: "25px",
  transition: "left 0.3s ease, width 0.3s ease",
  zIndex: 0, // Este z-index lo hace estar por debajo del texto
}));

const NavegationForSection = ({ menuItems, sx = {} }) => {
  const [hoverIndex, setHoverIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [itemWidths, setItemWidths] = useState([]);

  const itemRefs = useRef([]);

  useEffect(() => {
    const updateWidths = () => {
      const widths = itemRefs.current.map(
        (ref) => ref?.getBoundingClientRect().width || 0
      );
      setItemWidths(widths);
    };

    updateWidths(); // Inicializa los anchos al montar

    window.addEventListener("resize", updateWidths);
    return () => {
      window.removeEventListener("resize", updateWidths);
    };
  }, [menuItems]);

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  const handleClick = (index, sectionId) => {
    setSelectedIndex(index);
    scrollToSection(sectionId);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const calculateLeft = (index) => {
    return index !== null ? itemRefs.current[index]?.offsetLeft || 0 : 0;
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffff",
        boxShadow:
          "0px 4px 6px rgba(0, 0, 0, 0.1), 0px -2px 4px rgba(0, 0, 0, 0.1)",
        padding: "1px 1px",
        borderRadius: "25px",
        position: "relative",
        display: "inline-block", // Esto asegura que el ancho del Box sea el mismo que el de sus hijos
        maxWidth: "100%", // Opcional, en caso de que quieras un control extra
        ...sx,
      }}
    >
      <CustomMenuList onMouseLeave={handleMouseLeave}>
        {menuItems.map((item, index) => (
          <li
            key={index}
            ref={(el) => (itemRefs.current[index] = el)}
            onMouseEnter={() => handleMouseEnter(index)}
            onClick={() => handleClick(index, item.sectionId)}
            style={{
              cursor: "pointer",
              position: "relative",
              padding: "10px 20px",
              fontWeight: "400",
              fontFamily: "'Poppins', sans-serif",
              fontSize: "1rem",
              color:
                selectedIndex === index || hoverIndex === index
                  ? "#fff"
                  : "#200E24",
              transition: "color 0.3s ease",
              zIndex: 1, // Asegúrate de que el texto esté por encima del span
            }}
          >
            {item.label}
          </li>
        ))}
        {hoverIndex !== null && (
          <EfectoSpan
            width={itemWidths[hoverIndex] || 0}
            left={calculateLeft(hoverIndex)}
          />
        )}
        <EfectoSpan
          width={itemWidths[selectedIndex] || 0}
          left={calculateLeft(selectedIndex)}
        />
      </CustomMenuList>
    </Box>
  );
};

export default NavegationForSection;
