import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";
import { AddShoppingCart, InsertChart, Code } from "@mui/icons-material";

// Constantes para los datos
const SERVICIOS = {
  pequeñasEmpresas: [
    {
      titulo: "Publicidad Digital",
      subtitulo:
        "Consigue tráfico cualificado, leads, clientes potenciales, ventas, etc",
      icono: <AddShoppingCart sx={{ color: "#F21C63" }} />,
      enlace: "/servicios/publicidad-digital",
    },
  ],
  grandesEmpresas: [
    {
      titulo: "Inbound Marketing",
      subtitulo:
        "Mejora el ROAS con estrategias personalizadas de inbound marketing",
      icono: <InsertChart sx={{ color: "#4CAF50" }} />,
      enlace: "/servicios/inbound-marketing",
    },
  ],
  solucionesAvanzadas: [
    {
      titulo: "Desarrollo de Software",
      subtitulo:
        "Construimos sistemas SaaS, IaaS y PaaS fáciles de adoptar y desplegar",
      icono: <Code sx={{ color: "#2196F3" }} />,
      enlace: "/servicios/desarrollo-software",
    },
  ],
};

const NavegacionInternaServicios = () => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
        backgroundColor: "#fff",
      }}
    >
      {/* Hijo de 70% */}
      <Box sx={{ display: "flex", height: "80%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            position: "relative",
            marginTop: "40px",
          }}
        >
          {/* Título */}
          <Typography
            variant="h4"
            sx={{ fontWeight: "400", fontSize: "1.2rem" }}
          >
            Servicios
          </Typography>

          {/* Línea horizontal */}
          <Box
            sx={{
              width: "100%",
              height: "0.5px",
              backgroundColor: "#ddd",
              marginTop: "20px",
              marginBottom: "40px",
            }}
          />

          {/* Grid con columnas */}
          <Grid container spacing={2}>
            {/* Columna 1 */}
            <Grid item xs={12} md={4}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.8rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                PARA MARCAS Y PEQUEÑAS EMPRESAS
              </Typography>
              {/* Items */}
              {SERVICIOS.pequeñasEmpresas.map((item, index) => (
                <Link
                  key={index}
                  href={item.enlace} // Aquí se agrega el enlace
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    mt: 1,
                    mb: 1,
                    textDecoration: "none",
                  }}
                >
                  {/* Icono */}
                  <Box sx={{ mr: 2 }}>{item.icono}</Box>
                  {/* Título y subtitulo */}
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "500",
                        mt: 1,
                        fontSize: "0.75rem",
                        width: "90%",
                        "&:hover": { color: "#F21C63" },
                      }}
                    >
                      {item.titulo}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        mb: 1,
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.75rem",
                        width: "80%",
                      }}
                    >
                      {item.subtitulo}
                    </Typography>
                  </Box>
                </Link>
              ))}
            </Grid>

            {/* Columna 2 */}
            <Grid item xs={12} md={4}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.8rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                PARA GRANDES EMPRESAS
              </Typography>
              {/* Items */}
              {SERVICIOS.grandesEmpresas.map((item, index) => (
                <Link
                  key={index}
                  href={item.enlace} // Aquí se agrega el enlace
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    mt: 1,
                    mb: 1,
                    textDecoration: "none",
                  }}
                >
                  {/* Icono */}
                  <Box sx={{ mr: 2 }}>{item.icono}</Box>
                  {/* Título y subtitulo */}
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "500",
                        mt: 1,
                        fontSize: "0.75rem",
                        width: "90%",
                        "&:hover": { color: "#F21C63" },
                      }}
                    >
                      {item.titulo}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.75rem",
                        width: "80%",
                      }}
                    >
                      {item.subtitulo}
                    </Typography>
                  </Box>
                </Link>
              ))}
            </Grid>

            {/* Columna 3 */}
            <Grid item xs={12} md={4}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.8rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                SOLUCIONES AVANZADAS
              </Typography>
              {/* Items */}
              {SERVICIOS.solucionesAvanzadas.map((item, index) => (
                <Link
                  key={index}
                  href={item.enlace} // Aquí se agrega el enlace
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    mt: 1,
                    mb: 1,
                    textDecoration: "none",
                  }}
                >
                  {/* Icono */}
                  <Box sx={{ mr: 2 }}>{item.icono}</Box>
                  {/* Título y subtitulo */}
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "500",
                        mt: 1,
                        fontSize: "0.75rem",
                        width: "90%",
                        "&:hover": { color: "#F21C63" },
                      }}
                    >
                      {item.titulo}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontWeight: "400",
                        mt: 1,
                        fontSize: "0.75rem",
                        width: "80%",
                      }}
                    >
                      {item.subtitulo}
                    </Typography>
                  </Box>
                </Link>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ position: "relative", heigth: "20%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#f4f4f4",
            padding: 2,
            borderRadius: "8px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
              fontSize: "0.9rem",
              mb: 1,
            }}
          >
            Recursos:
          </Typography>
          <Link
            href="#"
            sx={{
              display: "block",
              color: "#211E26",
              textDecoration: "none",
              fontSize: "0.7rem",
              fontWeight: "500",
              mb: 1,
              "&:hover": {
                color: "#F21C63",
              },
            }}
          >
            Noticias
          </Link>
          <Link
            href="#"
            sx={{
              display: "block",
              color: "#211E26",
              textDecoration: "none",
              fontSize: "0.7rem",
              fontWeight: "500",
              mb: 1,
              "&:hover": {
                color: "#F21C63",
              },
            }}
          >
            Crecimiento
          </Link>
          <Link
            href="#"
            sx={{
              display: "block",
              color: "#211E26",
              textDecoration: "none",
              fontSize: "0.7rem",
              fontWeight: "500",
              mb: 1,
              "&:hover": {
                color: "#F21C63",
              },
            }}
          >
            Asociaciones
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default NavegacionInternaServicios;
