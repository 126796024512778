import LayoutDynamicPage from "../landing/LayoutDinamicPage";
import automatizacionDeMarketingData from "../marketing/data/informacion/automatizacionDeMarketingData";

const AutomatizacionDeMarketing = () => {
  const {
    portadaProps,
    menuItems,
    sectionVideoProps,
    acordeonInteligenteProps,
    acordeonInteligenteImagenProps,
    cartasResultadosProps,
    seccionCuerpoImagenData,
    datosTestimonios,
    adicionalescartasResultadosProps,
    SeccionConTituloYAcordeonProps,
    llamadoAlaAccionProps,
  } = automatizacionDeMarketingData;
  return (
    <LayoutDynamicPage
      portadaProps={portadaProps}
      menuItems={menuItems}
      sectionVideoProps={sectionVideoProps}
      acordeonInteligenteProps={acordeonInteligenteProps}
      acordeonInteligenteImagenProps={acordeonInteligenteImagenProps}
      cartasResultadosProps={cartasResultadosProps}
      seccioneCuerpoImagenData={seccionCuerpoImagenData}
      datosTestimonios={datosTestimonios}
      adicionalescartasResultadosProps={adicionalescartasResultadosProps}
      SeccionConTituloYAcordeonProps={SeccionConTituloYAcordeonProps}
      llamadoAlaAccionProps={llamadoAlaAccionProps}
    />
  );
};

export default AutomatizacionDeMarketing;
