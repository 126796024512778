import React from "react";
import BannerDePaginaPrincipal from "../components/banners/bannerDePaginaPrincipal/bannerPaginaPrincipal"; // Asegúrate de que la ruta sea correcta
import AboutUsSection from "../nosotros/components/AboutUsSection";
import CeoCard from "../nosotros/components/CeoCard";
import StepGrid from "../nosotros/components/StepGrid";
import TeamMembers from "./components/TeamMembers";
import ServiceReviews from "./../home/components/ServiceReviews";
import FAQSection from "./../nosotros/components/FAQSection";
import Footer from "../footer/page";
import { Box } from "@mui/material";

const Nosotros = () => {
  return (
    <Box>
      
      {/* Usa BannerDePaginaPrincipal con las props */}
      <BannerDePaginaPrincipal 
        title="¡HOLA! BIENVENIDO A" 
        subtitle="HITPOLY" 
      />

      <AboutUsSection />
      <CeoCard />
      <TeamMembers />
      <StepGrid />
      <Box sx={{marginTop: "150px"}}>
      <ServiceReviews />
      </Box>
      <FAQSection />
      <Footer />
    </Box>
  );
};

export default Nosotros;
