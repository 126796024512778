import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

const PaginaDeInicio = () => {
  const [wordIndex, setWordIndex] = useState(0);

  const words = [
    "Le damos resultados tangibles a tu visión",
    "Revolucionamos tu camino hacia el éxito",
    "Creamos un futuro de oportunidades",
  ];
  const colors = ["#6C4DE2", "#FBBC05", "#0B8DB5"]; // Morado, Amarillo y Azul

  useEffect(() => {
    const interval = setInterval(() => {
      setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 4000);

    return () => clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonte
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: { xs: "100%", md: "80vh" },
        marginTop: { xs: "30px", md: "0px" },
        color: "#211E26",
        textAlign: "center",
      }}
    >
      {/* Título */}
      <Typography
        variant="h2"
        sx={{
          width: { xs: "90%", md: "60%" },
          fontWeight: "600",
          marginBottom: "10px",
          fontSize: { xs: "1.9rem", md: "2.3rem" },
          animation: `slideUp 1s ease-out`,
        }}
      >
        Somos el motor digital de la transformación económica en Latinoamérica,
      </Typography>

      <Typography
        variant="h2"
        sx={{
          width: { xs: "95%", md: "80%" },
          color: colors[wordIndex], // Color dinámico
          fontWeight: "600",
          animation: `slideUp 1s ease-out`,
          fontFamily: "Poppins, sans-serif",
          fontSize: { xs: "1.9rem", md: "2.3rem" },
          textAlign: "center", // Centra el texto
          marginBottom: "20px", // Espacio entre textos
        }}
      >
        {words[wordIndex]}
      </Typography>

      {/* Subtítulo */}
      <Typography
        variant="h5"
        sx={{
          width: { xs: "85%", md: "45%" },
          fontWeight: "400",
          marginBottom: "30px",
          fontSize: { xs: "1rem", md: "16px" },
        }}
      >
        Ayudamos a marcas, empresas y profesionales con soluciones innovadoras
        de capitalización digital, creando oportunidades de crecimiento
        sostenibles y generando un impacto económico positivo.
      </Typography>

      {/* Botones */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "15px",
          marginBottom: "30px",
          alignItems: "center", // Asegura que los botones se centren horizontalmente
          justifyContent: "center", // Asegura que los botones se centren en el contenedor
        }}
      >
        <Button
          component={Link}
          to="https://meetings.hubspot.com/hitpoly?uuid=34a2179d-26a9-46dc-9eae-98738ae67fae"
          variant="contained"
          sx={{
            width: { xs: "100%", sm: "250px" },
            backgroundColor: "#F21C63",
            color: "#fff",
            padding: "10px 20px",
            fontWeight: "500",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#f22066",
            },
          }}
        >
          Soy una Marca/Empresa
        </Button>

        <Button
          component={Link}
          to="https://wa.me/51933961352"
          variant="outlined"
          sx={{
            width: { xs: "100%", sm: "250px" },
            borderColor: "#F21C63",
            color: "#F21C63",
            padding: "10px 20px",
            fontWeight: "500",
            textTransform: "none",
            "&:hover": {
              borderColor: "#bf0a46",
              color: "#bf0a46",
            },
          }}
        >
          Soy un Profesional
        </Button>
      </Box>

      <Typography
        variant="body1"
        sx={{
          color: "#0B8DB5",
          fontFamily: "Poppins", // Fuente personalizada
          width: "80%", // Limita el ancho para mejor lectura
          fontSize: "0.8rem",
          fontWeight: "600",
          lineHeight: "1.6",
          textTransform: "uppercase",
          animation: "bounce 2s infinite", // Aplica la animación
          "@keyframes bounce": {
            "0%, 100%": {
              transform: "translateY(0)", // Posición inicial y final
            },
            "50%": {
              transform: "translateY(-1px)", // Desplazamiento hacia arriba
            },
          },
        }}
      >
        En hitpoly Capitalizas de forma segura.
      </Typography>
    </Box>
  );
};

export default PaginaDeInicio;
