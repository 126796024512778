import { Box } from "@mui/material";
import { Typography } from "@mui/material";

const Descripcion = ({ descripcion }) => {
  return (
    <Box
      sx={{
        padding: { xs: "0px 20px", md: "50px 150px" },
        paddingTop: { xs: "50px", md: "80px" },
      }}
    >
      {descripcion.map((paragraph, index) => (
        <Typography
          sx={{
            marginBottom: 3,
            width: { xs: "100%" },
            fontSize: "1.2rem",
          }}
        >
          {paragraph}
        </Typography>
      ))}
    </Box>
  );
};

export default Descripcion;
