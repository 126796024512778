import React from "react";
import Navbar from "../navbarHomeGral/navbarGral";
import BannerDePaginaPrincipal from "../components/banners/bannerDePaginaPrincipal/bannerPaginaPrincipal"; // Asegúrate de que la ruta sea correcta
import SectionCard from "./components/SectionCards";
import Footer from "../footer/page";
import { Box } from "@mui/material";

const Servicios = () => {
  return (
    <Box>
      <Navbar />
      
      {/* Reemplaza HomeService con BannerDePaginaPrincipal y pasa las props */}
      <BannerDePaginaPrincipal 
        title="SOLUCIONAMOS PROBLEMAS" 
        subtitle="DE IMPACTO INTERNACIONAL" 
      />

      <SectionCard />
      <Footer />
    </Box>
  );
};

export default Servicios;
