import Box from "@mui/material/Box";
import TituloSubtituloCentro from "../../components/texto/TituloSubtituloCentro/cuerpoEnElCentro";
import CardPrecio from "../../components/cards/cardPrecio/cardPrecio";
import Footer from "../../footer/page";
import LlamadoALaAccion from "../../servicios/internas/landing/secciones/llamadoALaAccionFinal";
import Descripcion from "../../components/texto/descripcion/descripcion";

const Pricing = ({ pricingData }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          minHeight: "100vh",
          marginTop: { xs: 5, md: 10 },
        }}
      >
        <Box sx={{ width: "100%", padding: "40px" }}>
          <TituloSubtituloCentro
            title={pricingData.titulo}
            subtitle={pricingData.subtitulo}
          />
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 2,
              padding: { xs: "0px 20px", md: "50px 150px" },
              paddingTop: { xs: "50px", md: "80px" },
            }}
          >
            {pricingData.planes.map((precio, index) => (
              <Box
                key={index}
                sx={{
                  width: { xs: "100%", sm: "48%", md: "23%" },
                  marginBottom: 2,
                }}
              >
                <CardPrecio
                  titulo={precio.titulo}
                  precioReal={precio.precioReal}
                  precioDescuento={precio.precioDescuento}
                  descripcion={precio.descripcion}
                  beneficios={precio.beneficios}
                  to={precio.to}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <Box sx={{ padding: "10px" }}>
          <Descripcion descripcion={pricingData.descripcion} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: { xs: "40px", md: "80px 150px" },
          }}
        >
          <LlamadoALaAccion
            title={pricingData.llamadoAlaAccionProps.title}
            icon={pricingData.llamadoAlaAccionProps.icon}
            firstButton={pricingData.llamadoAlaAccionProps.firstButton}
            secondButton={pricingData.llamadoAlaAccionProps.secondButton}
          />
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Pricing;
