import CardSoluciones from "../components/cards/cardSoluciones/cardSoluciones";
import { Box } from "@mui/material";
import OfertaEspecial from "../components/texto/ofertaEspecial/ofertaEspecial";
import SliderComponent from "../components/sliders/sliderComponent";
import CardModernizacion from "../components/cards/cardModernizacion/cardModernizacion";

const Soluciones = () => {
  return (
    <>
      <Box
        id="seccio4"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "center",
          gap: "20px",
          paddingTop: { xs: "50px", md: "80px" },
        }}
      >
        <CardSoluciones />
        <CardSoluciones />
        <CardSoluciones />
      </Box>
      <Box
        sx={{
          textAlign: "center",
          padding: "24px",
          margin: " 5rem auto", // Centrar horizontalmente
        }}
      >
        <OfertaEspecial />
      </Box>
      <Box
        sx={{
          textAlign: "center",
          padding: "24px",
          margin: "5rem auto", // Centrar horizontalmente
        }}
      >
        {/* <SliderComponent /> */}
      </Box>
      <Box
        sx={{
          textAlign: "center",
          padding: "24px",
          maxWidth: "70%",
          margin: "5rem auto", // Centrar horizontalmente
        }}
      >
        <CardModernizacion />
      </Box>
    </>
  );
};

export default Soluciones;
