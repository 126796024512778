import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import NavGral from "../navbarHomeGral/navbarGral";
import Footer from "../footer/page";

const Disclaimer = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Card 
                sx={{
                    maxWidth: { xs: '90%', sm: '80%', md: 600 }, // Ancho máximo ajustable según el tamaño de pantalla
                    margin: 'auto',
                    marginTop: { xs: 2, md: 4 }, // Margen superior ajustable para diferentes resoluciones
                    padding: { xs: 2, md: 3 }, // Padding ajustable para diferentes tamaños de pantalla
                    boxShadow: 3, // Sombra para mejorar la visibilidad
                }}
            >
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom align="center">
                        Descargo de responsabilidad
                    </Typography>
                    <Typography variant="body1" align="justify"> {/* Alineación justificada para una mejor legibilidad */}
                        La información contenida en este sitio web es solo para fines de información general. La información es proporcionada por hitpoly y, si bien nos esforzamos por mantener la información actualizada y correcta, no hacemos representaciones ni garantías de ningún tipo, expresas o implícitas, sobre la integridad, precisión, confiabilidad, idoneidad o disponibilidad con respecto al sitio web o la información, productos, servicios o gráficos relacionados contenidos en el sitio web para cualquier propósito.

                        Por lo tanto, cualquier confianza que usted deposite en dicha información es estrictamente bajo su propio riesgo. En ningún caso seremos responsables de ninguna pérdida o daño, incluidos, entre otros, pérdidas o daños indirectos o consecuentes, o cualquier pérdida o daño que surja de la pérdida de datos o ganancias que surjan de, o en conexión con, el uso de este sitio web.

                        A través de este sitio web, usted puede establecer enlaces a otros sitios web que no están bajo el control de hitpoly. No tenemos control sobre la naturaleza, el contenido y la disponibilidad de esos sitios. La inclusión de cualquier enlace no implica necesariamente una recomendación ni respalda las opiniones expresadas en ellos. Se hace todo lo posible para mantener el sitio web funcionando sin problemas. Sin embargo, hitpoly no se hace responsable ni será responsable de que el sitio web no esté disponible temporalmente debido a problemas técnicos fuera de nuestro control.
                    </Typography>
                </CardContent>
            </Card>
            <Footer />
        </div>
    );
}

export default Disclaimer;
