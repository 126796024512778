import React from "react";
import { Box, Typography } from "@mui/material";
import AsesoriaPersonalizada from "../../../../components/botones/botonAsesoria";  
import AgendaUnaConsulta from "../../../../components/botones/agendaUnaConsulta";  
import PublicIcon from '@mui/icons-material/Public'; 
import HomeIcon from '@mui/icons-material/Home';  

const iconMapping = {
  "Public": PublicIcon,
  "Home": HomeIcon,
};

const LlamadoALaAccion = ({
  title = "Gestión de Redes Sociales",
  icon: iconName,  
  firstButton = { text: "Solicitar Asesoría", to: "/" },
  secondButton = null, // Se establece en null por defecto
}) => {
  const Icon = iconMapping[iconName];  

  return (
    <Box
      sx={{
        backgroundColor: "#f4f4f4",
        borderRadius: "15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: "50px",
        width: "100%",
        margin: "0 auto",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Renderiza el ícono dinámicamente si el componente existe */}
      {Icon && <Icon sx={{ fontSize: 60, color: "#1E88E5", marginBottom: 2 }} />}

      {/* Título dinámico, se pasa como prop */}
      <Typography
        variant="h6"
        sx={{
          marginBottom: 3,
          width: { xs: "100%", md: "80%" },
          fontSize: {xs: "2rem", md: "2.5rem"},
          fontWeight: 500,
          color: "#333",
        }}
      >
        {title}
      </Typography>

      {/* Botones dinámicos */}
      <Box
        sx={{
          width: { xs: "100%", md: "40%" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "16px",
          flexWrap: "wrap",
        }}
      >
        <AsesoriaPersonalizada text={firstButton.text} to={firstButton.to} />
        
        {/* Solo muestra el segundo botón si se pasa */}
        {secondButton && (
          <AgendaUnaConsulta text={secondButton.text} to={secondButton.to} />
        )}
      </Box>
    </Box>
  );
};

export default LlamadoALaAccion;
