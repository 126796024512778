import React from "react";
import Box from "@mui/material/Box";
import { Route, Routes } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import "./App.css";
import MenuDeNavegacion from "./Views/navbar/navVers03/contenedor.jsx";
import MenuMobile from "./Views/navbar/navVers03/menuDeNavegacionMobile.jsx";

// Tus otras importaciones aquí
import Login from "./Views/login/page";
import Register from "./Views/register/page";
import DashboardPage from "./Views/dashboard/page";
import DiseñoWeb from "./Views/internalPages/diseñoWeb/pages.jsx";
import Marketing from "./Views/internalPages/Marketing/pages.jsx";
import Home from "./Views/home/page.jsx";
import Portfolio from "./Views/portfolio/page.jsx";
import Blog from "./Views/blog/page.jsx";
import Nosotros from "./Views/nosotros/page.jsx";
import Contact from "./Views/contact/page.jsx";
import PrivacyPolicy from "./Views/pageLegals/PrivacyPolicy.jsx";
import Disclaimer from "./Views/pageLegals/disclaimer.jsx";
import Terms from "./Views/pageLegals/termsandconditions.jsx";
import Copyright from "./Views/pageLegals/Copyright.jsx";
import Servicios from "./Views/servicios/page.jsx";
import ServicioDesarrolloSoftware from "./Views/home/components/servicios/data/ServicioDesarrolloSoftware.jsx";
import ServicioMarketingContenidos from "./Views/home/components/servicios/data/ServicioMarketingContenidos.jsx";
import ServicioCampañasPublicitarias from "./Views/home/components/servicios/data/ServicioCampañasPublicitarias.jsx";
import ServicioDesarrolloWeb from "./Views/home/components/servicios/data/ServicioDesarrolloDePaginasWeb.jsx";
import ServicioDesarrolloDeAplicacionesMoviles from "./Views/home/components/servicios/data/ServicioDesarrolloDeAplicaciones.jsx";
import ServicioEmailMarketing from "./Views/home/components/servicios/data/ServicioEmailMarketing.jsx";
import RedesSociales from "./Views/servicios/internas/marketing/redesSociales.jsx";
import PublicidadDigital from "./Views/servicios/internas/marketing/publicidadDigital.jsx";
import EmailMarketing from "./Views/servicios/internas/marketing/emailMarketing.jsx";
import InboundMarketing from "./Views/servicios/internas/marketing/inboundMarketing.jsx";
import AsesoriaDeInboundMarketing from "./Views/servicios/internas/marketing/asesoriaDeInboundMarketing.jsx";
import AutomatizacionDeMarketing from "./Views/servicios/internas/marketing/automatizacionDeMarketing.jsx";
import DesarrolloDeSoftware from "./Views/servicios/internas/marketing/desarrolloDeSoftware.jsx";
import ComunidadDeMarketing from "./Views/comunidad/marketing.jsx"
import ComunidadDeComercio from "./Views/comunidad/comercio.jsx"
import ComunidadDeTecnologia from "./Views/comunidad/tecnologia.jsx"

//Card Princing Prueba
import PricingRedesSociales from "./Views/price/servicios/redesSocialesPrecio.jsx";
import PricingPublicidadDigital from "./Views/price/servicios/publicidadDigitalPrecio.jsx";
import PricingEmailMarketing from "./Views/price/servicios/emailMarkeitngPrecio.jsx";
import PricingInboundMarketing from "./Views/price/servicios/inboundMarketingPrecio.jsx";
import PricingAsesoriaInbound from "./Views/price/servicios/asesoriaDeInboundMarketingPrecio.jsx";
import PricingDesarrolloDeSoftware from "./Views/price/servicios/asesoriaDeInboundMarketingPrecio.jsx";


import Soluciones from "./Views/soluciones/page.jsx";

function App() {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Box sx={{backgroundColor: "#fff", position: "fixed", zIndex: 999, width: "100%", height: "65px", boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.5)",}}>
        {isLargeScreen ? <MenuDeNavegacion /> : <MenuMobile />}
      </Box>
      <Box sx={{ position: "absolute", top: "65px", width: "100%", }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/nosotros" element={<Nosotros />} />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/DiseñoWeb" element={<DiseñoWeb />} />
          <Route path="/Marketing" element={<Marketing />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/termsandconditions" element={<Terms />} />
          <Route path="/Copyright" element={<Copyright />} />

          {/* Servicios internos */}
          <Route path="/servicios/redes-sociales" element={<RedesSociales />} />
          <Route path="/servicios/publicidad-digital" element={<PublicidadDigital />} />
          <Route path="/servicios/email-marketing" element={<EmailMarketing />} />
          <Route path="/servicios/inbound-marketing" element={<InboundMarketing />} />
          <Route path="/servicios/asesoria-inbound" element={<AsesoriaDeInboundMarketing />} />
          <Route path="/servicios/automatizacion-de-marketing" element={<AutomatizacionDeMarketing />} />
          <Route path="/servicios/desarrollo-software" element={<DesarrolloDeSoftware />} />

          {/* Precios por servicio */}
          <Route path="/servicios/redes-sociales/precios" element={<PricingRedesSociales />} />
          <Route path="/servicios/publicidad-digital/precios" element={<PricingPublicidadDigital />} />
          <Route path="/servicios/email-marketing/precios" element={<PricingEmailMarketing />} />
          <Route path="/servicios/inbound-marketing/precios" element={<PricingInboundMarketing />} />
          <Route path="/servicios/asesoria-inbound/precios" element={<PricingAsesoriaInbound />} />
          <Route path="/servicios/desarrollo-software/precios" element={<PricingDesarrolloDeSoftware />} />

          {/* Pagina de soluciones */}
          <Route path="/soluciones" element={<Soluciones />} />

          


          <Route path="/desarrollo-de-software-a-medida" element={<ServicioDesarrolloSoftware />} />
          <Route path="/marketing-de-contenidos" element={<ServicioMarketingContenidos />} />
          <Route path="/campañas-publicitarias" element={<ServicioCampañasPublicitarias />} />
          <Route path="/paginas-web-de-alta-conversion" element={<ServicioDesarrolloWeb />} />
          <Route path="/aplicaciones-moviles-a-medida" element={<ServicioDesarrolloDeAplicacionesMoviles />} />
          <Route path="/campañas-de-email-marketing" element={<ServicioEmailMarketing />} />


          {/* Cards de prueba */}
          <Route path="/comunidades/marketing" element={<ComunidadDeMarketing />} />
          <Route path="/comunidades/comercio" element={<ComunidadDeComercio />} />
          <Route path="/comunidades/tecnologia" element={<ComunidadDeTecnologia />} />

        </Routes>
      </Box>
    </>
  );
}

export default App;
