import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const AboutUsSection = () => {
  // Estado para el acordeón abierto
  const [expanded, setExpanded] = useState("panel1");

  // Manejar el cambio de acordeón
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Determina el orden de los acordeones
  const getOrder = () => {
    if (expanded === "panel2") {
      return ["panel2", "panel3", "panel1"];
    } else if (expanded === "panel3") {
      return ["panel3", "panel1", "panel2"];
    }
    return ["panel1", "panel2", "panel3"];
  };

  const [first, second, third] = getOrder();

  return (
    <Grid
      sx={{
        padding: { xs: "0px 20px", lg: "0px 40px" },
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        maxWidth: "100%",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Grid
        container
        spacing={4}
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          width: "98%",
          padding: 0,
          boxSizing: "border-box",
        }}
      >
        {/* Columna de Descripción */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: { xs: 3, md: 0 }, padding: 0, width: "98%" }}>
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                color: "#211E26",
                fontSize: { xs: "1.125rem", sm: "1.125rem", md: "1.25rem" },
              }}
            >
              QUIENES SOMOS
            </Typography>

            <Typography
              variant="h3"
              sx={{
                fontWeight: "500",
                mb: 3,
                color: "#211E26",
                fontSize: "2.5rem",
                fontWeight: "500",
                textAlign: { xs: "left", md: "left" },
              }}
            >
              Acerca de nuestra empresa
            </Typography>

            <Typography
              variant="body1"
              sx={{
                mb: 3,
                color: "#211E26",
                fontSize: "1rem",
              }}
            >
              Somos una fuerza motriz detrás de la innovación transformadora y
              el cambio social positivo. Reconocemos que el futuro pertenece a
              aquellos que pueden adaptarse rápidamente a las demandas del
              mercado y abrazar la innovación disruptiva. Nuestro compromiso con
              el impacto social refleja nuestra creencia en que las empresas
              tienen la responsabilidad de contribuir al bienestar de la
              sociedad en su conjunto.
            </Typography>
          </Box>
        </Grid>

        {/* Columna de Acordeones */}
        <Grid item xs={12} md={6}>
          <Box sx={{ padding: 0, width: "98%", marginLeft: { xs: 0, md: 2 } }}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{
                bgcolor: "#FFFFFF",
                color: "#211E26",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "12px",
                marginBottom: 2,
              }}
            >
              <AccordionSummary
                expandIcon={
                  <AddIcon
                    sx={{
                      color: "#FFFFFF",
                      backgroundColor: "#F21C63",
                      borderRadius: "5px",
                    }}
                  />
                }
              >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  NUESTRA MISIÓN
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    color: "#211E26",
                  }}
                >
                  Nuestra misión es liberar el potencial humano a través de la
                  tecnología, elevando los estándares de la innovación y la
                  responsabilidad.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={{
                bgcolor: "#FFFFFF",
                color: "#211E26",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "12px",
                marginBottom: 2,
              }}
            >
              <AccordionSummary
                expandIcon={
                  <AddIcon
                    sx={{
                      color: "#FFFFFF",
                      backgroundColor: "#0B8DB5",
                      borderRadius: "5px",
                    }}
                  />
                }
              >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  NUESTRA VISIÓN
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    color: "#211E26",
                  }}
                >
                  Nuestra visión es ser pioneros en la creación de un futuro
                  donde cada solución tecnológica inspire progreso humano.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              sx={{
                bgcolor: "#FFFFFF",
                color: "#211E26",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "12px",
              }}
            >
              <AccordionSummary
                expandIcon={
                  <AddIcon
                    sx={{
                      color: "#FFFFFF",
                      backgroundColor: "#6C4DE2",
                      borderRadius: "5px",
                    }}
                  />
                }
              >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  NUESTROS VALORES
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    color: "#211E26",
                  }}
                >
                  Valoramos la colaboración, la inclusión, y el impulso hacia la
                  excelencia a través de la innovación.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AboutUsSection;
