import React from "react";
import { Card, CardMedia, CardContent, Typography, Box } from "@mui/material";

const CardBeneficios = ({
  image,
  title,
  description,
  cardStyles = {},
  imageHeight = 150, 
  imageWidth = "100%",
}) => {
  return (
    <Card
      sx={{
        height: "100%",
        maxWidth: 345,
        margin: "0px 0px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)", // Añade una sombra suave
        borderRadius: "8px", // Opcional: redondea los bordes
        transition: "transform 0.3s ease, box-shadow 0.3s ease", // Animación para transformaciones suaves
        "&:hover": {
          boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.3)", // Aumenta la sombra al hacer hover
          transform: "scale(1.05)", // Aumenta ligeramente el tamaño al hacer hover
        },
        ...cardStyles, // Permite estilos personalizados adicionales
      }}
    >
      {/* Contenedor para la imagen en la parte superior */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center", // Centra la imagen horizontalmente
          mb: 2, // Margen inferior para separar la imagen del contenido
        }}
      >
        <CardMedia
          component="img"
          height={imageHeight}
          width={imageWidth}
          image={image}
          alt={title}
          sx={{
            objectFit: "contain", // Ajuste de la imagen sin que se distorsione
            maxHeight: imageHeight, // Respetar la altura máxima
            maxWidth: imageWidth, // Respetar el ancho máximo
          }}
        />
      </Box>

      {/* Contenido de la tarjeta */}
      <CardContent sx={{ textAlign: "center" }}>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ fontSize: "1rem" }}>
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardBeneficios;
