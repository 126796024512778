import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import NavGral from "../navbarHomeGral/navbarGral";
import Footer from "../footer/page";

const PrivacyPolicy = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Card 
                sx={{
                    maxWidth: { xs: '90%', sm: '80%', md: 600 }, // Ancho máximo ajustable según el tamaño de pantalla
                    margin: 'auto',
                    marginTop: { xs: 2, md: 4 }, // Margen superior ajustable para diferentes resoluciones
                    padding: { xs: 2, md: 3 }, // Padding ajustable para diferentes tamaños de pantalla
                    boxShadow: 3, // Sombra para mejorar la visibilidad
                }}
            >
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom align="center">
                        Política de Privacidad
                    </Typography>
                    <Typography variant="body1" align="justify"> {/* Alineación justificada para una mejor legibilidad */}
                        En hitpoly, la privacidad de nuestros visitantes es de suma importancia para nosotros. Este documento de política de privacidad describe los tipos de información personal que hitpoly recibe y recopila y se utiliza.

                        Archivos de registro: Como muchos otros sitios web, hitpoly utiliza archivos de registro. La información dentro de los archivos de registro incluye direcciones de protocolo de Internet (IP), tipo de navegador, proveedor de servicios de Internet (ISP), marca de fecha/hora, páginas de referencia/salida y número de clics para analizar tendencias, administrar el sitio y rastrear el movimiento del usuario alrededor del sitio y recopilar información demográfica. Las direcciones IP y otra información similar no están vinculadas a ninguna información que sea de identificación personal.

                        Cookies y balizas web: hitpoly utiliza cookies para almacenar información sobre las preferencias de los visitantes, registrar información específica del usuario en qué páginas accede o visita, personaliza el contenido de la página web según el tipo de navegador de los visitantes u otra información que el visitante envía a través de su navegador.

                        Cookie DART: De doble clic Google, como proveedor externo, utiliza cookies para publicar anuncios en hitpoly. El uso que hace Google de la cookie DART le permite mostrar anuncios a los usuarios según su visita a hitpoly y otros sitios en Internet. Los usuarios pueden optar por no utilizar la cookie de DART visitando la política de privacidad de la red de contenido y publicidad de Google en la siguiente URL: https://www.google.com/policies/privacy/.

                        Estos servidores de anuncios o redes publicitarias de terceros utilizan tecnología para que los anuncios y enlaces que aparecen en hitpoly se envíen directamente a sus navegadores. Reciben automáticamente su dirección IP cuando esto ocurre. Las redes publicitarias de terceros también pueden utilizar otras tecnologías (como cookies, JavaScript o Web Beacons) para medir la eficacia de sus anuncios y/o personalizar el contenido publicitario que usted ve. hitpoly no tiene acceso ni control sobre estas cookies que utilizan los anunciantes externos. Debe consultar las políticas de privacidad respectivas de estos servidores de anuncios de terceros para obtener información más detallada sobre sus prácticas, así como para obtener instrucciones sobre cómo excluirse de ciertas prácticas. La política de privacidad de hitpoly no se aplica a otros anunciantes o sitios web, y no podemos controlar las actividades de estos. Si desea desactivar las cookies, puede hacerlo a través de las opciones individuales de su navegador. Puede encontrar información más detallada sobre la gestión de cookies con navegadores web específicos en los sitios web respectivos del navegador.
                    </Typography>
                </CardContent>
            </Card>
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;
