import React from "react";
import { Box } from "@mui/material"; // Asegúrate de importar Box de MUI
import UneteAComunidadLayout from "../components/botones/comunidades/internasInformativas/comunidades";

const ComunidadDeTecnologia = () => {
  const title = "¡Únete a Nuestra Comunidad de Tecnología!";
  const description =
    "Forma parte de un grupo exclusivo de WhatsApp donde compartimos las últimas novedades en tecnología, tendencias de innovación, herramientas digitales, y consejos prácticos para que puedas aprovechar al máximo el poder de la tecnología. Además, te ofrecemos oportunidades laborales para freelancers, colaboraciones en proyectos tecnológicos y acceso a nuevas vacantes en el mundo digital. Conoce a otros apasionados por la tecnología, aprende de expertos y mantente al día con los avances tecnológicos más relevantes. ¡Únete ahora y lleva tu conocimiento a otro nivel!";
  const whatsappLink = "https://chat.whatsapp.com/FtZzvUkUBNACCWHCsAxqlc"; // Reemplaza con el enlace correcto de WhatsApp
  const imageUrl = "/images/comunidadDeTecnologia.jpg"; // Reemplaza con la imagen adecuada

  return (
    <Box>
      <UneteAComunidadLayout
        title={title}
        description={description}
        whatsappLink={whatsappLink}
        imageUrl={imageUrl}
      />
    </Box>
  );
};

export default ComunidadDeTecnologia;
