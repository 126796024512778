import React from "react";
import { Box } from "@mui/material";
import TituloSubtituloCentro from "../../../../components/texto/TituloSubtituloCentro/cuerpoEnElCentro";
import ProgressAccordion from "../../../../components/acordeones/acordeonProgressAccordion";
import ImagenPersonalizada from "../../../../components/imagenes/Imagen/imagenPage";

// Componente reutilizable AcordeonInteligente
const AcordeonInteligenteImagen = ({
  title,
  subtitle,
  panelsData,
  imgSrc,
  imgAlt,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Título y subtítulo */}
      <TituloSubtituloCentro
        title={title}
        subtitle={subtitle}
        titleColor="#211E26"
        subtitleColor="text.secondary"
      />

      {/* Contenedor del acordeón y imagen */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // En pantallas grandes, fila, en móviles columna
          alignItems: "center",
          width: "100%",
          marginTop: "60px",
          gap: "50px", // Espacio entre los elementos
        }}
      >
        {/* Imagen (a la izquierda) */}
        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ImagenPersonalizada
            src={imgSrc}
            alt={imgAlt}
            width="100%"
            height="auto"
            borderRadius="16px"
            sx={{
              maxWidth: "550px",
              maxHeight: "400px",
              width: { xs: "100%", md: "auto" },
            }}
          />
        </Box>

        {/* Acordeón (a la derecha) */}
        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            maxWidth: 600,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "80%" },
            }}
          >
            <ProgressAccordion panels={panelsData} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AcordeonInteligenteImagen;
