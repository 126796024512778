import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Link,
  Box,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import EventIcon from "@mui/icons-material/Event";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InfoIcon from "@mui/icons-material/Info";

const CardSoluciones = () => {
  return (
    <Card
      sx={{
        borderRadius: "16px",
        border: "1px solid #BDBDBD", // Color medio gris
        maxWidth: 400,
        padding: "16px",
      }}
    >
      <CardContent>
        {/* Título general */}
        <Typography
          variant="h4"
          component="div"
          align="center"
          gutterBottom
        >
          Moderniza
        </Typography>

        {/* Descripción */}
        <Typography
          variant="body2"
          align="center"
          color="text.secondary"
          gutterBottom
        >
          Aumenta la productividad y automatiza las tareas cotidianas.
        </Typography>

        {/* Línea divisoria */}
        <Divider sx={{ marginY: 2, border: "1px solid rgb(101, 100, 100)" }} />

        {/* Primer conjunto: íconos, título y descripción */}
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          alignContent="center"
          gap={1}
        >
          <EmailIcon color="primary" />
          <Typography
            variant="h6"
            component="div"
          >
            Google Workspace
          </Typography>
        </Box>
        <Typography
          variant="body2"
          color="text.secondary"
          gutterBottom
          align="center"
        >
          Correo electrónico, calendario, reuniones, edición y MDM avanzada para
          administrar tu flota de dispositivos móviles.
        </Typography>

        {/* Segundo conjunto: ícono, título y descripción */}
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          alignContent="center"
          gap={1}
          mt={2}
        >
          <AddCircleOutlineIcon color="primary" />
          <Typography
            variant="h6"
            component="div"
          >
            AppSheet Core
          </Typography>
        </Box>
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          gutterBottom
        >
          Crea aplicaciones completas y procesos automatizados sin escribir ni
          una línea de código.
        </Typography>

        {/* Enlace de más información */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={1}
          mt={2}
        >
          <InfoIcon color="primary" />
          <Link
            href="#"
            underline="hover"
          >
            Más información
          </Link>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardSoluciones;
