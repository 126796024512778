import React from "react";
import { Box, Typography, Button, Link } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const UneteAComunidadLayout = ({ title, description, whatsappLink, imageUrl }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        height: {xs: "100%", md: "100vh"},
        backgroundColor: "#fff",
        alignItems: "center",
        padding: {xs: 2, md: 1},
      }}
    >
      {/* Contenedor de la descripción y el botón en el 70% */}
      <Box
        sx={{
          width: { xs: "90%", md: "60%" },
          padding: { md: 10},
          marginTop: {xs: "20px", md: "0px"},
          paddingRight: { xs: 0, md: "150px" },
        }}
      >
        {/* Título */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { xs: "1.5rem", md: "2rem" }, 
            mb: 2,
            textAlign: "left",
            color: "#333",
          }}
        >
          {title}
        </Typography>

        {/* Descripción */}
        <Typography
          variant="body1"
          sx={{
            fontWeight: "400",
            fontSize: { xs: "0.9rem", md: "1rem" }, 
            color: "#555",
            mb: 3,
            lineHeight: "1.5",
            textAlign: "left",
          }}
        >
          {description}
        </Typography>

        {/* Botón para unirse al grupo */}
        <Button
          variant="contained"
          color="primary"
          sx={{
            fontWeight: "600",
            fontSize: { xs: "0.9rem", md: "1rem" }, 
            textTransform: "none",
            backgroundColor: "#25D366", 
            "&:hover": {
              backgroundColor: "#128C7E", 
            },
            padding: { xs: "8px 16px", md: "10px 20px" }, 
            display: "flex",
            alignItems: "center",
          }}
          startIcon={<WhatsAppIcon />}
          component={Link}
          href={whatsappLink}
          target="_blank"
        >
          Únete al Grupo de WhatsApp
        </Button>
      </Box>

      {/* Contenedor de la imagen en el 30% */}
      <Box
        sx={{
          width: { xs: "100%", md: "40%" }, 
          height: {xs: "500px", md: "100%"},
          backgroundImage: `url(${imageUrl})`, 
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: { xs: "10px 10px 0 0", md: "0 10px 10px 0" }, 
          marginTop: { xs: "20px", md: 0 },
        }}
      />
    </Box>
  );
};

export default UneteAComunidadLayout;
